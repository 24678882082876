import {
  Typography,
  Button,
  notification,
  Form,
  Input,
  Upload,
  Progress,
} from "antd";
import { useState } from "react";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstanceSync } from "../../services/axios";
import { InboxOutlined } from "@ant-design/icons";

const { Title } = Typography;

const GET_ATTACHMENTS = gql`
  query GetAttachments($id: String!) {
    orderService: getOrderServiceClient(id: $id) {
      description_client
      attachments {
        id
        name
        path
      }
    }
  }
`;
interface GetAttachments {
  orderService: {
    description_client?: string;
    attachments: { id: string; name: string; path: string; state?: any }[];
  };
}

export default function EditAttachments() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id!;
  const { data, loading, refetch } = useQuery<GetAttachments>(GET_ATTACHMENTS, {
    fetchPolicy: "cache-and-network",
    variables: { id },
    context: { uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL },
  });

  const orderService = data?.orderService;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const client = useApolloClient();
  const [uploadProgress, setUploadProgress] = useState(0);

  return (
    <section style={{ padding: "1rem 0" }}>
      <div className="flex justify-between">
        <Title level={2}>Editar Observação e Anexos</Title>
        <Button
          htmlType="submit"
          form="edit_observation_form"
          type="primary"
          loading={isSubmitting}
        >
          Salvar e voltar
        </Button>
      </div>

      {loading && !orderService && (
        <Title level={5} style={{ textAlign: "center" }}>
          Carregando...
        </Title>
      )}

      {!!orderService && (
        <Form
          id="edit_observation_form"
          layout="vertical"
          onFinish={(values) => {
            setIsSubmitting(true);
            axiosInstanceSync
              .put(
                `/client/order-service/${id}/update`,
                { data: values },
                { baseURL: process.env.REACT_APP_API_VISTORIA + "/api" }
              )
              .then(() => {
                navigate(-1);
                notification.success({
                  message: "Dados atualizados com sucesso!",
                  duration: 1,
                });
              })
              .catch((e) => {
                notification.error({
                  message: e.response?.data?.error ?? e.message ?? String(e),
                  duration: 1,
                });
              })
              .finally(() => setIsSubmitting(false));
          }}
        >
          <Form.Item name="description_client" label="Editar observação">
            <Input.TextArea defaultValue={orderService.description_client} />
          </Form.Item>

          <Upload.Dragger
            name="files"
            multiple
            fileList={orderService.attachments.map((it) => ({
              name: it.name,
              uid: it.id,
              ...it.state,
            }))}
            beforeUpload={async (file) => {
              const formData = new FormData();
              formData.append("files", file);
              await axiosInstanceSync.post(
                `/client/order-service/${id}/upload-attachment`,
                formData,
                {
                  onUploadProgress: (state) =>
                    setUploadProgress(state.progress ?? 0),
                }
              );
              await refetch();
              setTimeout(() => setUploadProgress(0), 400);
              return false;
            }}
            onRemove={async (file) => {
              // optmistically hide file from UI
              client.writeQuery({
                query: GET_ATTACHMENTS,
                variables: { id },
                data: {
                  orderService: {
                    ...orderService,
                    attachments: orderService.attachments.filter(
                      (it) => it.id !== file.uid
                    ),
                  },
                },
              });

              await axiosInstanceSync.post(
                `/client/order-service/${file.uid}/remove-attachment`
              );
              await refetch();
            }}
            progress={{}}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Clique ou arraste o arquivo para esta área para fazer upload
            </p>
          </Upload.Dragger>

          {!!uploadProgress && (
            <Progress percent={Math.floor(uploadProgress * 100)} />
          )}
        </Form>
      )}
    </section>
  );
}
