import { useCallback } from "react";
import {
  Button,
  Dropdown,
  Popover,
  TablePaginationConfig,
  Tag,
  Typography,
  Col,
  Row,
} from "antd";
import { RightOutlined } from "@ant-design/icons";

import GET_OS_LIST from "../../common/graphql/GetOSList";
import RequestsList from "../../components/RequestsList";
import OrderService from "../../common/interfaces/OrderService";
import StatusMenu from "./StatusMenu";
import Filters from "./Filters";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const DEFAULT_OPTIONS = {
  page: 1,
  offset: 0,
  limit: 10,
  orderBy: { order: "DESC", column: "date_solicitation" },
};

interface PaginatorQuery {
  paginator: {
    data: OrderService[];
    paginationInfo: any;
  };
}

function parseBoolean(data: string) {
  if (data === "true") {
    return true;
  } else if (data === "false") {
    return false;
  }

  return data;
}

export default function OSList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = localStorage.getItem(
    `${process.env.REACT_APP_USER_PROFILE_KEY}`
  );
  const user = JSON.parse(value || "");
  const { page, ...filters } = Object.fromEntries(searchParams.entries());
  const pageAsNumber = Number(page) || 1;
  const filtersState = {
    ...filters,
    isContestationDone: parseBoolean(filters.isContestationDone),
  };
  const { data, loading, refetch } = useQuery<PaginatorQuery>(GET_OS_LIST, {
    fetchPolicy: "cache-and-network",
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
    variables: {
      options: {
        ...DEFAULT_OPTIONS,
        page: pageAsNumber,
        offset: DEFAULT_OPTIONS.limit * pageAsNumber - DEFAULT_OPTIONS.limit,
      },
      filters: {
        ...filtersState,
        enterpriseId: user?.enterpriseId,
      },
    },
  });

  const paginationInfo = data?.paginator.paginationInfo;
  let dataSource = data?.paginator.data;

  const tablePagination: TablePaginationConfig = {
    onChange: (page) => {
      setSearchParams((prev) => {
        const next = new URLSearchParams(prev);
        next.set("page", String(page));
        return next;
      });
    },

    defaultCurrent: Number(searchParams.get("page")) || 1,
    defaultPageSize: 10,
    total: paginationInfo?.total,
    showSizeChanger: false,
  };

  const onFilter = useCallback((values: Partial<Values>) => {
    setSearchParams(
      (prev) => {
        const next = new URLSearchParams();
        for (let [key, value] of Object.entries(values)) next.set(key, value);
        return next;
      },
      { replace: true }
    );
  }, []);

  return (
    <section className="p-4">
      <Row className="flex justify-between items-center w-full mb-3">
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          Minhas solicitações
        </Typography.Title>

        <Col>
          <Popover
            overlayStyle={{
              width: 464,
            }}
            placement="bottomRight"
            content={<Filters {...{ onFilter }} />}
            trigger="click"
          >
            <Button type="primary">Filtros</Button>
          </Popover>
        </Col>
      </Row>

      <RequestsList
        {...{
          loading,
          dataSource,
          pagination: tablePagination,
        }}
        columns={[
          {
            title: "Status",
            dataIndex: "status",
            render(status: string) {
              let colorName = "#808080";
              if (status === "Vistoriado") colorName = "#0000FF";
              else if (status === "Contestado") colorName = "#FF8C00";
              else if (status === "Respondido") colorName = "#0D5246";
              else if (status === "Finalizado") colorName = "#228B22";
              else if (status === "Entregue") colorName = "#7FFF00";
              else if (status === "Cancelado") colorName = "#FF0000";
              return <Tag color={colorName}>{status}</Tag>;
            },
          },
          {
            title: "",
            render({ status, id, ...rest }: OrderService) {
              return (
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <StatusMenu
                      {...{
                        status,
                        id,
                        data: { status, id, ...rest },
                      }}
                      refetch={() => refetch()}
                    />
                  }
                  placement="topLeft"
                >
                  <Button icon={<RightOutlined />} />
                </Dropdown>
              );
            },
          },
        ]}
      />
    </section>
  );
}

export type Values = {
  numberRequestInternal: string;
  date_solicitation: string;
  date_inspection: string;
  date_schedule_inspection: string;
  status: string;
  addressInspection: string;
  neighborhoodInspection: string;
  cityInspection: string;
  stateInspection: string;
  userName: string;
  isContestationDone: string;
};
