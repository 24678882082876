import React, { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { Avatar, Button, Dropdown, Menu, Layout, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

import { useAuth } from "../../contexts/auth";
import { useStatement } from "../../contexts/statement";
import Logo from "../../assets/images/logo-bhouse.png";
import Metreage from "../../components/Metreage";

const styles = {
  container: "bg-white flex items-center justify-center gap-2",
  brand: "inline-flex h-full items-center flex-1",
  balance: "justify-center text-sm font-semibold h-2/3",
};

const GET_CURRENT_PLAN_ENTERPRISE_CLIENT_BALANCE = gql`
  query getBalance($enterpriseId: String!) {
    getCurrentPlanEnterpriseClient(enterpriseId: $enterpriseId) {
      balance_metreage
    }
  }
`;

const Header: React.FC = () => {
  const { user } = useAuth();
  const { statement, setStatement } = useStatement();

  const { data, loading, refetch } = useQuery(
    GET_CURRENT_PLAN_ENTERPRISE_CLIENT_BALANCE,
    {
      variables: {
        enterpriseId: user?.enterpriseId,
        isCurrent: true,
      },
      context: {
        uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
      },
    }
  );

  useEffect(() => {
    if (statement) {
      refetch().then(() => {
        setStatement(false);
      });
    }
  }, [refetch, setStatement, statement]);

  const metreage = data?.getCurrentPlanEnterpriseClient?.balance_metreage;

  return (
    <Layout.Header className={styles.container}>
      <div className={styles.brand}>
        <img
          style={{ filter: "brightness(1.2) opacity(0.95)" }}
          className="h-24 w-auto py-1"
          src={Logo}
          alt="B House Vistorias"
        />
      </div>
      <Space size="middle">
        <Metreage
          className={styles.balance}
          title="Saldo disponível"
          metreage={metreage}
          metreageClassName={metreage ? getMetreageStatus(metreage) : ""}
          loading={loading}
        />
        <Dropdown overlay={<FinalityInspectionMenu />}>
          <Button style={{ color: "#1890ff", borderColor: "#1890ff" }}>
            <Link to="/new-inspection" className="flex items-center gap-1">
              <span>Solicitar vistoria</span>
              <DownOutlined />
            </Link>
          </Button>
        </Dropdown>

        <Avatar src={user?.avatar} />
      </Space>
    </Layout.Header>
  );
};

function FinalityInspectionMenu() {
  const items = [
    { text: "entrada", query: "entry" },
    { text: "saída", query: "exit" },
    { text: "comparação", query: "comparison" },
  ];

  const location = useLocation();

  return (
    <Menu
      className="shadow"
      style={{
        boxShadow:
          "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      }}
    >
      {items.map(({ query, text }, i) => (
        <React.Fragment key={query}>
          <Menu.Item>
            <Link
              to={`/new-inspection?finality_inspection=${query}`}
              replace={location.pathname === "new-inspection"}
              className="block capitalize font-medium text-center"
            >
              {text}
            </Link>
          </Menu.Item>
          <Menu.Divider />
        </React.Fragment>
      ))}
    </Menu>
  );
}

function getMetreageStatus(metreage: number) {
  if (metreage < 500) {
    return "text-red-600";
  } else if (metreage >= 500 && metreage < 1500) {
    return "text-yellow-500";
  } else {
    return "text-green-500";
  }
}

export default Header;
