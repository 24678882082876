import { gql } from "@apollo/client";

const GET_OS_LIST_CLIENT = gql`
  query getOS(
    $options: PaginatorOption
    $filters: FilterOrderServicesPaginatorClient!
  ) {
    paginator: getOrderServicesPaginatorClient(
      options: $options
      filters: $filters
    ) {
      data {
        id
        number_request_internal
        metreage
        enterprise {
          id
          planEnterprise {
            price_plan
          }
        }
        planEnterpriseInvoice {
          metreage
        }
        consumptionMetreage {
          metreage
          metreage_distance
          metreage_time_waiting
          metreage_immmobile_have_furniture
          metreage_immobile_type
          metreage_inspection_type
          metreage_delivery_report
          metreage_immobile_is_reform
          metreage_finality_inspection
        }
        status
        date_solicitation
        date_inspection
        date_schedule_inspection
        hour_report_delivery
        date_finish_report
        date_end_delivery_report
        finality_inspection
        user {
          id
          name
        }
        inspection {
          id
          dateAcceptInspection
          dateRejectInspection
          pathReportOrderService
          pathReportOrderServiceWithoutPhoto
          inspector {
            id
            user {
              name
              avatar
            }
          }
          contestation {
            id
            path
            urlSite
            report {
              id
              path
            }
          }
        }
        address_inspection {
          address
          number
          complement
          neighborhood
        }
      }
      paginationInfo {
        count
        currentPage
        lastPage
        perPage
        total
      }
    }
  }
`;

export default GET_OS_LIST_CLIENT;
