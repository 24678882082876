import { Tabs } from "antd";
import { useSearchParams } from "react-router-dom";
import EditSigners from "./EditSigners";
import EditAttachments from "./EditAttachments";

export default function OSEdit() {
  const [searchParams] = useSearchParams();

  return (
    <main style={{ padding: "1rem" }}>
      <Tabs
        defaultActiveKey={searchParams.get("tab") ?? ""}
        items={[
          {
            key: "signers",
            label: "Editar Signatários",
            children: <EditSigners />,
          },
          {
            key: "attachments",
            label: "Editar Observação e Anexos",
            children: <EditAttachments />,
          },
        ]}
      />
    </main>
  );
}
