import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Typography, Avatar, Menu, Layout } from "antd";

import {
  FileTextTwoTone,
  HomeTwoTone,
  LogoutOutlined,
  DollarCircleTwoTone,
  IdcardTwoTone,
  DiffTwoTone,
} from "@ant-design/icons";
import { useQuery, gql } from "@apollo/client";
import { useAuth } from "../../contexts/auth";
import { useUser } from "../../hooks/useUser";

export default function Sidebar() {
  const { user: localUser } = useAuth();

  // if (!localUser) throw new Error('User does not exists');

  const userQuery = useUser({ id: localUser!.id });
  const user = userQuery.data?.user;

  const { data } = useQuery(
    gql`
      query getEnterprise($id: String!) {
        getEnterprise(id: $id) {
          name
        }
      }
    `,
    {
      variables: {
        id: localUser ? `${localUser.enterpriseId}` : "",
      },
      context: {
        uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
      },
    }
  );

  const [sidebarCollapse] = useState(true);
  const { signOut } = useAuth();
  // const toggleSidebar = () => setSidebarState(b => !b);

  return (
    <Layout.Sider
      className={`app-sidebar ${sidebarCollapse ? "w-48" : "w-36"} ${
        process.env.REACT_APP_MODE == "development" ? "dev" : ""
      }`}
    >
      <Row
        align="middle"
        className={`h-16 px-4 ${
          sidebarCollapse ? "justify-start" : "justify-center"
        } `}
        style={{ backgroundColor: "#f0f2f5" }}
      >
        <div className="grid grid-cols-[auto_1fr] gap-2">
          <Avatar src={localUser?.avatar} />
          {sidebarCollapse && (
            <Col className="grid">
              <h2 className="font-semibold text-l overflow-hidden whitespace-nowrap text-ellipsis">
                {data?.getEnterprise?.name}
              </h2>
              {sidebarCollapse && (
                <Typography.Text className="overflow-hidden whitespace-nowrap text-ellipsis">
                  {user?.name}
                </Typography.Text>
              )}
            </Col>
          )}
        </div>
      </Row>

      <Menu inlineCollapsed={!sidebarCollapse} mode="inline">
        {menuItems.map(({ key, icon, children }) => (
          <Menu.Item {...{ key, icon, children }} />
        ))}
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined style={{ color: "#ff4d4f" }} />}
          onClick={signOut}
        >
          <Link to="/login">Sair</Link>
        </Menu.Item>
      </Menu>
      <div
        className="flex justify-center"
        style={{ marginTop: "calc(100vh - 450px)" }}
      >
        <Typography.Text
          strong
          style={{ alignSelf: "self-end", textAlign: "center" }}
        >
          Versão 1.4.0
        </Typography.Text>
      </div>
    </Layout.Sider>
  );
}

const menuItems = [
  {
    key: "dashboard",
    icon: <HomeTwoTone />,
    children: <Link to="/dashboard">Dashboard</Link>,
  },
  {
    key: "my-requests",
    icon: <FileTextTwoTone />,
    children: <Link to="/my-requests">Minhas solicitações</Link>,
  },
  {
    key: "statement",
    icon: <DollarCircleTwoTone />,
    children: <Link to="/statement">Extrato</Link>,
  },
  {
    key: "my-data",
    icon: <IdcardTwoTone />,
    children: <Link to="/information">Meus dados</Link>,
  },
  {
    key: "suggestions",
    icon: <DiffTwoTone />,
    children: <Link to="/suggestions">Sugestão</Link>,
  },
];
