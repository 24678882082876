import { Layout, notification } from "antd";
import { Outlet } from "react-router-dom";
import React from "react";

import Sidebar from "./Sidebar";
import Header from "./Header";
import { Provider } from "react-redux";
import store from "../../common/redux";
import { useAuth } from "../../contexts/auth";
import Link from "antd/lib/typography/Link";
import { useEffect } from "react";
import { NetPromoterScore } from "./NetPromoterScore";
import { useApolloClient } from "@apollo/client";
import GET_OS_LIST_CLIENT from "../../common/graphql/GetOSList";
import NavigationProgress from "../../components/NavigationProgress";
// import SidebarToggle from '../components/icons/SidebarToggle';

const AppLayout = () => {
  const { user } = useAuth();
  const apollo = useApolloClient();
  useEffect(() => {
    const sse = new EventSource(`${process.env.REACT_APP_API_VISTORIA}/sse`);

    sse.addEventListener("report-order-service-done", handleEvent);
    return () =>
      sse.removeEventListener("report-order-service-done", handleEvent);

    function handleEvent({ data }: MessageEvent<any>) {
      const event = JSON.parse(data);
      if (event?.enterpriseId === user?.enterpriseId) {
        apollo.refetchQueries({ include: [GET_OS_LIST_CLIENT] });
        notification.success({
          duration: 0,
          message: event?.msg,
          description: (
            <>
              Clique{" "}
              <Link href={event?.link} target="_blank">
                aqui
              </Link>{" "}
              para baixar o laudo.
            </>
          ),
        });
      }
    }
  }, [apollo, user?.enterpriseId]);

  const [isNpsOpen, setNpsOpen] = React.useState(false);
  const close = () => setNpsOpen(false);

  useEffect(() => {
    if (user) {
      setNpsOpen(!!user.showSearchNps);
    }
  }, [user]);

  const markNpsShown = () => {
    try {
      const key = process.env.REACT_APP_USER_PROFILE_KEY ?? "";
      const userString = localStorage.getItem(key) ?? "";
      const user = JSON.parse(userString);
      user.showSearchNps = false;
      localStorage.setItem(key, JSON.stringify(user));
    } finally {
    }
  };

  return (
    <Provider store={store}>
      <NavigationProgress />

      <NetPromoterScore
        open={isNpsOpen}
        onClose={close}
        onSent={markNpsShown}
      />

      <Layout>
        <Header />
        <Layout>
          <Sidebar />
          <Layout.Content className="w-full">
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Provider>
  );
};

export default AppLayout;
