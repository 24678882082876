import { Table, Tag, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";

import { dateformatter } from "../common/helpers/Formatter";
import Address from "../common/interfaces/Address";
import OrderService from "../common/interfaces/OrderService";
import { PlanEnterpriseInvoice } from "../common/interfaces/PlanEnterpriseInvoice";
import Enterprise from "../common/interfaces/Enterprise";

interface Props {
  columns: ColumnsType<OrderService>;
  dataSource: OrderService[] | undefined;
  loading: boolean;
  pagination?: TablePaginationConfig;
}

export default function RequestsList({
  columns,
  dataSource,
  loading,
  pagination,
}: Props) {
  return (
    <Table
      rowKey={(s) => s.id}
      {...{
        loading,
        dataSource,
        pagination,
        columns: [...defaultColumns, ...columns],
      }}
    />
  );
}

const defaultColumns = [
  {
    title: "Nº Pedido",
    dataIndex: "number_request_internal",
    render(text: string, row: OrderService) {
      let color = "blue";
      if (
        row.finality_inspection === "location_exit" ||
        row.finality_inspection === "location_comparation"
      )
        color = "red";
      return <Tag color={color}>{text}</Tag>;
    },
  },
  {
    title: "Solicitante",
    dataIndex: ["user", "name"],
    render: (text: string) => {
      if (typeof text !== "string") return "";
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : "-";
    },
  },
  {
    title: "Vistoriador",
    dataIndex: ["inspection", "inspector", "user", "name"],
    render: (text: string) => {
      if (typeof text !== "string") return "";
      return text ? text.charAt(0).toUpperCase() + text.slice(1) : "-";
    },
  },
  {
    title: "Endereço",
    dataIndex: "address_inspection",
    render(a: Address) {
      if (!a) return "-";
      return (
        <div>
          {a.address}, {a.neighborhood}, {a.number}. {a.complement}
        </div>
      );
    },
  },
  {
    title: "Metragem",
    dataIndex: "metreage",
    render(n: number) {
      return (
        <div>
          {n} m<sup>2</sup>
        </div>
      );
    },
  },
  {
    title: "Consumido",
    dataIndex: "planEnterpriseInvoice",
    render(p: PlanEnterpriseInvoice) {
      const invoiceMetreage = p?.metreage ?? 0;
      return (
        <div>
          {invoiceMetreage.toFixed(2)} m<sup>2</sup>
        </div>
      );
    },
  },
  // {
  //   title: 'Valor',
  //   dataIndex: 'enterprise',
  //   render(e: Enterprise, row: OrderService) {
  //     const consumptionValue = (row.planEnterpriseInvoice?.metreage ?? 0) * (e.planEnterprise?.price_plan ?? 0)
  //     return (
  //       <div>R$ {consumptionValue.toFixed(2).replace('.', ',')}</div>
  //     );
  //   },
  // },
  {
    title: "Data de solicitação",
    dataIndex: "date_solicitation",
    render(d: string) {
      return dateformatter(d, "dd/MM/yyyy HH:mm");
    },
  },
  {
    title: "Data de agendamento",
    dataIndex: "date_schedule_inspection",
    render(d: string, row: OrderService) {
      if (row.finality_inspection === "location_entry")
        return "Horário comercial";
      return dateformatter(d, "dd/MM/yyyy HH:mm") ?? "-";
    },
  },
];
